import { IconButton, InputAdornment, styled, TextField as MuiTextField, TextFieldProps } from '@mui/material';
import moment from 'moment-timezone';
import { IMaskInput } from 'react-imask';
import React, { ChangeEvent, useState, useMemo, Ref } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Iconify from '~components/iconify/Iconify';
const TextField = styled(MuiTextField)(() => ({
  '&': {
    background: 'transparent'
  }
}));
type Props = TextFieldProps & {
  name: string;
  hideError?: boolean;
  startAdornment?: any;
  mask?: any;
};
function RHFTextField({
  name,
  helperText,
  hideError,
  onChange,
  startAdornment,
  mask,
  // Add mask prop for IMaskInput
  ...other
}: Props) {
  const {
    control
  } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const handleChange = ({
    e,
    field
  }: any) => {
    let value = e.target.value;
    if ((name || '').toLowerCase().includes('email')) value = e.target.value.trim();
    field.onChange(value);
    if (onChange) onChange(value);
  };
  const IMaskTextFieldComponent = useMemo(() => {
    if (mask) {
      return React.forwardRef((props, ref) => {
        return <IMaskInput {...props} mask={mask} inputRef={(ref as Ref<HTMLInputElement> | undefined)} />;
      });
    }
    return null;
  }, [mask]);
  return <Controller name={name} control={control} render={({
    field,
    fieldState: {
      error
    }
  }) => <TextField {...field} onPaste={e => handleChange({
    e,
    field
  })} onChange={(e: any) => handleChange({
    e,
    field
  })} fullWidth value={other?.type == 'date' ? moment(field.value).tz('America/Chicago').format('YYYY-MM-DD') : typeof field.value === 'number' && field.value === 0 ? '' : field.value || ''} error={!!error} helperText={error && !hideError ? error?.message : helperText} {...name.toLowerCase().includes('password') && {
    type: showPassword ? 'text' : 'password',
    InputProps: {
      endAdornment: <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
    }
  }} {...startAdornment && {
    InputProps: {
      sx: {
        background: 'white'
      },
      startAdornment: startAdornment
    }
  }} {...mask && {
    InputProps: {
      inputComponent: IMaskTextFieldComponent
    }
  }} {...other} />} data-sentry-element="Controller" data-sentry-component="RHFTextField" data-sentry-source-file="RHFTextField.tsx" />;
}
export default RHFTextField;