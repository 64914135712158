import { Box, Checkbox, Chip, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, SelectProps, TextField, TextFieldProps, Autocomplete, UseAutocompleteProps, SxProps, Dialog, DialogTitle, IconButton, List, ListItem, ListItemText, InputAdornment, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import { Control, Controller, useFormContext } from 'react-hook-form';
import React, { ReactNode, useState } from 'react';
import { Theme } from '@mui/material/styles';
import useResponsive from '~hooks/useResponsive';
type RHFSelectProps = TextFieldProps & {
  name: string;
  native?: boolean;
  maxHeight?: boolean | number;
  children: React.ReactNode;
  handleChange?: any;
  onOpen?: any;
  helperText?: string;
};
export function RHFSelect({
  name,
  native,
  maxHeight = 220,
  helperText,
  children,
  sx,
  handleChange,
  onOpen,
  ...other
}: RHFSelectProps) {
  const {
    control
  } = useFormContext();
  return <Controller name={name} control={control} render={({
    field: {
      onChange,
      onBlur,
      value
    },
    fieldState: {
      error
    }
  }) => <TextField value={value} onBlur={onBlur} onChange={e => {
    handleChange?.(e, onChange);
    onChange(e);
  }} select fullWidth SelectProps={{
    onOpen,
    native,
    MenuProps: {
      PaperProps: {
        sx: {
          ...(!native && {
            px: 1,
            maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
            '& .MuiMenuItem-root': {
              px: 1,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize'
            }
          })
        }
      }
    },
    sx: {
      textTransform: 'capitalize',
      ...sx
    }
  }} error={!!error} helperText={error ? error?.message : helperText} {...other}>
          {children}
        </TextField>} data-sentry-element="Controller" data-sentry-component="RHFSelect" data-sentry-source-file="RHFSelect.tsx" />;
}
type RHFSelect2Props = Partial<UseAutocompleteProps<any, false, false, false>> & {
  name: string;
  //native?: boolean;
  //maxHeight?: boolean | number;
  //children: React.ReactNode;
  sx?: SxProps<Theme> | undefined;
  options: any;
  getOptionKey: (item: any) => string;
  getOptionLabel: (item: any) => string;
  handleChange?: any;
  label: string;
  textFieldProps?: Partial<TextFieldProps>;
  // autoComplete: string,
  //onOpen?: any,
};
export function RHFSelect2({
  name,
  label,
  textFieldProps = {},
  //native,
  //maxHeight = 220,
  //children,
  sx,
  handleChange,
  //onOpen,
  options,
  getOptionKey,
  getOptionLabel,
  autoComplete,
  ...other
}: RHFSelect2Props) {
  const {
    control
  } = useFormContext();
  return <Controller name={name} control={control} render={({
    field: {
      onChange,
      onBlur,
      value
    },
    fieldState: {
      error
    }
  }) => <Autocomplete {...other} value={options?.length ? value : ''} onChange={(event, newValue) => {
    // Assuming newValue is the selected option object
    const newValueId = newValue ? getOptionKey(newValue) : value;
    handleChange?.(event, newValueId, onChange);
    onChange(newValueId); // Set the _id as the new value
  }} options={options}
  // getOptionKey={getOptionKey}
  getOptionLabel={getOptionLabel} sx={sx} autoHighlight
  // renderOption={(props, option) => (
  //   <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
  //     <img
  //       loading="lazy"
  //       width="20"
  //       srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
  //       src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
  //       alt=""
  //     />
  //     {option.label} ({option.code}) +{option.phone}
  //   </Box>
  // )}
  renderInput={(params: any) => <TextField {...params} onBlur={onBlur} label={label} error={!!error} helperText={error ? error?.message : textFieldProps.helperText} {...textFieldProps} inputProps={{
    ...params.inputProps
    // autoComplete: autoComplete, //'new-password', // disable autocomplete and autofill
  }} />} />} data-sentry-element="Controller" data-sentry-component="RHFSelect2" data-sentry-source-file="RHFSelect.tsx" />;
}
export interface RHFComboboxOption {
  key: string;
  label: string;
}
export interface RHFComboBoxProps {
  sx?: SxProps<Theme> | undefined;
  options: RHFComboboxOption[];
  label: string;
  name: string;
  helperText?: string;
}
export const RHFComboBox: React.FC<RHFComboBoxProps> = ({
  sx,
  options,
  label,
  name,
  helperText
}) => {
  // const isMobile = false; // Replace this with your mobile detection logic
  const isMobile = useResponsive('down', 'sm');
  return isMobile ? <RHFComboBoxMobile sx={sx} options={options} label={label} name={name} helperText={helperText} data-sentry-element="RHFComboBoxMobile" data-sentry-component="RHFComboBox" data-sentry-source-file="RHFSelect.tsx" /> : <RHFComboBoxDesktop sx={sx} options={options} label={label} name={name} helperText={helperText} data-sentry-element="RHFComboBoxDesktop" data-sentry-component="RHFComboBox" data-sentry-source-file="RHFSelect.tsx" />;
};
export const RHFComboBoxMobile: React.FC<RHFComboBoxProps> = ({
  sx,
  options,
  label,
  name,
  helperText
}) => {
  const {
    control
  } = useFormContext();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return <Controller name={name} control={control} render={({
    field,
    fieldState: {
      error
    }
  }) => <>
          <TextField sx={sx} fullWidth label={label} variant="outlined" value={options.find(option => option.key === field.value)?.label || ''} onClick={handleOpen} // Open modal on click
    InputProps={{
      readOnly: true,
      endAdornment: <>
                  {/* {field.value && (
                      <InputAdornment position="end" >
                        <IconButton onClick={() => field.onChange('')}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )} */}
                  <InputAdornment position="end">
                    <IconButton onClick={handleOpen}>
                      <ArrowDropDownIcon />
                    </IconButton>
                  </InputAdornment>
                </>
    }} />
          {(!!error || helperText) && <FormHelperText error={!!error}>
              {error ? error?.message : helperText}
            </FormHelperText>}
          <Dialog sx={{
      zIndex: 10000,
      '& .MuiPaper-root': {
        borderRadius: '8px',
        width: '100%',
        maxWidth: '600px',
        margin: '0'
      }
    }} fullScreen open={open} onClose={handleClose}>
            <DialogTitle>
              <Typography sx={{
          fontWeight: 'bold'
        }}>{label}</Typography>
              <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" style={{
          position: 'absolute',
          right: '8px',
          top: '16px'
        }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <List>
              {options.map(option => <ListItem button key={option.key} onClick={() => {
          field.onChange(option.key);
          handleClose();
        }}>
                  <ListItemText primary={option.label} />
                </ListItem>)}
            </List>
          </Dialog>
        </>} data-sentry-element="Controller" data-sentry-component="RHFComboBoxMobile" data-sentry-source-file="RHFSelect.tsx" />;
};
export const RHFComboBoxDesktop: React.FC<RHFComboBoxProps> = ({
  sx,
  options,
  label,
  name,
  helperText
}) => {
  const {
    control
  } = useFormContext();
  return <Controller name={name} control={control} render={({
    field,
    fieldState: {
      error
    }
  }) => <>
        <Autocomplete sx={sx} {...field} options={options} getOptionLabel={option => option.label} value={options.find(option => option.key === field.value) || null} // Ensure `value` matches an option
    renderInput={params => <TextField {...params} label={label} variant="outlined" />} onChange={(_, value) => field.onChange(value ? value.key : '')} // Store only the key in form state
    isOptionEqualToValue={(option, value) => option.key === value?.key} // Compare options by key
    />
        {(!!error || helperText) && <FormHelperText error={!!error}>
            {error ? error?.message : helperText}
          </FormHelperText>}
        </>} data-sentry-element="Controller" data-sentry-component="RHFComboBoxDesktop" data-sentry-source-file="RHFSelect.tsx" />;
};

// import React from 'react';
// import { useForm, SubmitHandler } from 'react-hook-form';
// import {RHFComboBox,RHFComboBoxOption} from './RHFSelect';

// interface IFormInput {
//   selectedItem: RHFComboBoxOption | null;
// }

// const options: RHFComboBoxOption[] = [
//   { key: '1', label: 'Option 1' },
//   { key: '2', label: 'Option 2' },
//   { key: '3', label: 'Option 3' },
// ];

// const App: React.FC = () => {
//   const {  handleSubmit } = useForm<IFormInput>({
//     defaultValues: {
//       selectedItem: null,
//     },
//   });

//   const onSubmit: SubmitHandler<IFormInput> = (data) => {
//     console.log('Selected item:', data.selectedItem);
//   };

//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <RHFComboBox options={options} label="Select an Option" name="selectedItem" />
//       <button type="submit">Submit</button>
//     </form>
//   );
// };

// export default App;

type RHFMultiSelectProps = SelectProps & {
  name: string;
  label?: string;
  chip?: boolean;
  checkbox?: boolean;
  placeholder?: string;
  helperText?: React.ReactNode;
  options: {
    label: string;
    value: string;
  }[];
};
export function RHFMultiSelect({
  name,
  chip,
  label,
  options,
  checkbox,
  placeholder,
  helperText,
  sx,
  ...other
}: RHFMultiSelectProps) {
  const {
    control
  } = useFormContext();
  const renderValues = (selectedIds: string[]) => {
    const selectedItems = options.filter(item => selectedIds.includes(item.value));
    if (!selectedItems.length && placeholder) {
      return <Box component="em" sx={{
        color: 'text.disabled'
      }}>
          {placeholder}
        </Box>;
    }
    if (chip) {
      return <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 0.5
      }}>
          {selectedItems.map(item => <Chip key={item.value} size="small" label={item.label} />)}
        </Box>;
    }
    return selectedItems.map(item => item.label).join(', ');
  };
  return <Controller name={name} control={control} render={({
    field,
    fieldState: {
      error
    }
  }) => <FormControl sx={sx}>
          {label && <InputLabel id={name}> {label} </InputLabel>}

          <Select {...field} multiple displayEmpty={!!placeholder} labelId={name} input={<OutlinedInput fullWidth label={label} error={!!error} />} renderValue={(renderValues as (value: unknown) => ReactNode)} MenuProps={{
      PaperProps: {
        sx: {
          px: 1,
          maxHeight: 280
        }
      }
    }} {...other}>
            {placeholder && <MenuItem disabled value="" sx={{
        py: 1,
        px: 2,
        borderRadius: 0.75,
        typography: 'body2'
      }}>
                <em> {placeholder} </em>
              </MenuItem>}

            {options.map(option => {
        const selected = field.value.includes(option.value);
        return <MenuItem key={option.value} value={option.value} sx={{
          py: 1,
          px: 2,
          borderRadius: 0.75,
          typography: 'body2',
          ...(selected && {
            fontWeight: 'fontWeightMedium'
          }),
          ...(checkbox && {
            p: 0.25
          })
        }}>
                  {checkbox && <Checkbox disableRipple size="small" checked={selected} />}

                  {option.label}
                </MenuItem>;
      })}
          </Select>

          {(!!error || helperText) && <FormHelperText error={!!error}>
              {error ? error?.message : helperText}
            </FormHelperText>}
        </FormControl>} data-sentry-element="Controller" data-sentry-component="RHFMultiSelect" data-sentry-source-file="RHFSelect.tsx" />;
}